import * as React from "react";

import ContentAdd from "@material-ui/icons/Add";
import { useHistory } from "react-router-dom";
import { ReactNode } from "react";
import { ButtonProps, CloneButton, Record, useTranslate } from "react-admin";
import {
  Button,
  ButtonClassKey,
  Fab,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import classnames from "classnames";
import useStyles from "./LinkedResourcesCreateButton.styles";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";

type LinkedResourcesCreateButtonProps = ButtonProps & {
  children?: JSX.Element;
  className?: string;
  classes?: object & Partial<ClassNameMap<ButtonClassKey>>;
  icon?: ReactNode;
  label: string;
  linkedResourceIdKey?: string;
  linkedResourceName?: string;
  record?: Record;
  redirect?: boolean;
  resource?: string;
  scrollToTop: boolean;
} & typeof defaultProps;

const defaultProps = Object.freeze({
  className: "",
  classes: {},
  icon: <ContentAdd />,
  label: "",
  linkedResourceIdKey: "",
  linkedResourceName: "",
  record: {},
  resource: "",
  scrollToTop: true,
});

const LinkedResourcesCreateButton = ({
  children,
  className,
  classes: overrideClasses,
  icon,
  label,
  linkedResourceIdKey,
  linkedResourceName,
  resource,
  record,
  scrollToTop,
  ...rest
}: LinkedResourcesCreateButtonProps): JSX.Element => {
  const history = useHistory();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const styles = useStyles();
  const translate = useTranslate();

  const linkedResourceOn: boolean =
    linkedResourceIdKey !== "" && linkedResourceName !== "" ? true : false;

  const linkedResourceQuery = linkedResourceOn
    ? `${linkedResourceName}Id=${record[`${linkedResourceIdKey}`]}`
    : "";

  const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    history.push({
      pathname: `/${resource}/create`,
      search: `?${linkedResourceQuery}`,
      state: { _scrollToTop: scrollToTop },
    });
  };

  return !isSmall ? (
    <CloneButton
      icon={icon}
      component={Button}
      className={className}
      label={label}
      onClick={onClick}
      {...rest}
    >
      {children}
    </CloneButton>
  ) : (
    <Fab
      component={Button}
      color="primary"
      className={classnames(styles.fab, className)}
      onClick={onClick}
      aria-label={label && translate(label)}
    >
      {icon}
    </Fab>
  );
};

LinkedResourcesCreateButton.defaultProps = defaultProps;
export default React.memo(LinkedResourcesCreateButton);

import React from "react";
import {
  List,
  ListProps,
  TextInput,
  SortButton,
  TopToolbar,
  CreateButton,
  ExportButton,
} from "react-admin";
import ClientGrid from "../ClientGrid";
import DocumentationLink from "../../DocumentationLink";

const Aside = () => (
  <div style={{ margin: "1em" }}>
    <DocumentationLink />
  </div>
);

const clientFilters = [
  <TextInput label="Name" source="name" alwaysOn />,
  <TextInput label="Key" source="key" alwaysOn />,
  <TextInput
    label="Pricing Container ID"
    source="pricingContainerId"
    alwaysOn
  />,
];

const ListActions = () => (
  <TopToolbar>
    <SortButton fields={["name", "active", "createAt", "updateAt"]} />,
    <CreateButton basePath="/clients" />
    <ExportButton />
  </TopToolbar>
);

const ClientList = (props: ListProps) => {
  return (
    <List
      {...props}
      component="div"
      title="SDK Clients"
      filters={clientFilters}
      aside={<Aside />}
      actions={<ListActions />}
      sort={{ field: "name", order: "ASC" }}
    >
      <ClientGrid />
    </List>
  );
};

export default ClientList;

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    width: 275,
    margin: "1.2rem 0.6rem",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  titleLink: {
    textDecoration: "none",
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardFooter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardDate: {
    fontSize: 13,
  },
  cardVersion: {
    fontSize: 12,
  },
  clientKeyInput: {
    display: "flex",
    margin: "14px 0",
  },
  cardActions: {
    display: "flex",
  },
});

export default useStyles;

import { DeleteButton, Record } from "react-admin";
import { ListContextProvider, useList } from "ra-core";
import {
  Datagrid,
  TextField,
  NumberField,
  BooleanField,
  DateField,
} from "ra-ui-materialui";
import LinkedResourcesEditButton from "../../LinkedResourcesEditButton";
import { useMemo } from "react";
import { Typography } from "@material-ui/core";
import LinkedResourcesCreateButton from "../../LinkedResourcesCreateButton";
import useStyles from "./ClientDomainsList.style";
import { ICreatedDomain } from "../../../interfaces/domain";

type ClientDomainsListProps = {
  record: Record;
  showCreate: boolean;
} & typeof defaultProps;

const defaultProps = Object.freeze({ record: {}, showCreate: false });
const ClientDomainsList = ({ record, showCreate }: ClientDomainsListProps) => {
  const styles = useStyles();
  const myData = useMemo(
    () =>
      record.domainWhitelist.map((domain: ICreatedDomain) => ({
        SDKClient: record["id"],
        ...domain,
      })),
    [record]
  );

  const ids = useMemo(
    () => record.domainWhitelist.map(({ id }: Pick<ICreatedDomain, "id">) => id),
    [record]
  );

  const listContext = useList({
    data: myData,
    ids,
    loading: false,
    loaded: true,
  });

  return (
    <ListContextProvider value={listContext}>
      <div className={styles.wrapper}>
      {showCreate && (
        <LinkedResourcesCreateButton
          label="Add new domain"
          className={styles.createButton}
          resource="domains"
          record={record}
          linkedResourceIdKey="id"
          linkedResourceName="client"
        />
      )}
      {myData.length > 0 ? (
        <Datagrid
          hasBulkActions={false}
          basePath="/domains"
          resource="domains"
          rowClick="show"
        >
          <TextField source="domain" />
          <TextField source="pageType" />
          <NumberField source="funnelStep" sortable={false} />
          <BooleanField source="isDownsell" sortable={false} />
          <DateField source="createAt" />
          <DateField source="updateAt" />
          <LinkedResourcesEditButton
            linkedResourceName="client"
            linkedResourceIdKey="SDKClient"
            mainResourceIdKey="id"
          />
          <DeleteButton label="" redirect={false} />
        </Datagrid>
      ) : (
        <Typography>No results found</Typography>
      )}
      </div>
    </ListContextProvider>
  );
};

ClientDomainsList.defaultProps = defaultProps;
export default ClientDomainsList;

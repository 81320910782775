import { useListContext } from "react-admin";
import ClientCard from "../ClientCard";
import useStyles from "./ClientGrid.styles";

const ClientGrid = () => {
  const { ids, data: clients } = useListContext();
  const classes = useStyles();
  return (
    <div className={classes.grid}>
      {ids.map((id) => {
        const client = clients[id];
        return <ClientCard client={client} key={id} />;
      })}
    </div>
  );
};

export default ClientGrid;

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  createButton: {
    display: "flex",
    alignSelf: "center",
  },
});

export default useStyles;

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "16.5rem",
  },
  textWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  button: {
    padding: "10px 14px",
    fontSize: 12,
    textDecoration: "none",
    border: "1px solid #100932",
    marginTop: "14px",
    fontFamily: "sans-serif",
    "&:hover": {
      backgroundColor: "#100932",
      color: "white",
    },
  },
});

export default useStyles;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  fab: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 60,
    left: "auto",
    position: "fixed",
    zIndex: 1000,
  },
}));

export default useStyles;

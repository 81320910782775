import { ChevronLeft } from "@material-ui/icons";
import * as React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  UrlField,
  ShowProps,
  ReferenceField,
  BooleanField,
  TopToolbar,
  ListButton,
  EditButton,
  DeleteButton,
} from "react-admin";

const DomainShowActions = ({ basePath, id }: any) => {
  return (
    <TopToolbar>
      <ListButton basePath={basePath} icon={<ChevronLeft />} />
      <EditButton record={{ id }} basePath={basePath} />
      <DeleteButton record={{ id }} />
    </TopToolbar>
  );
};

const DomainShow = (props: ShowProps) => {
  return (
    <Show
      {...props}
      actions={<DomainShowActions basePath={props.basePath} id={props.id} />}
    >
      <SimpleShowLayout>
        <ReferenceField
          source="SDKClient"
          reference="clients"
          sortable={false}
          label="SDK Client"
        >
          <TextField source="name" sortable={false} />
        </ReferenceField>
        <TextField source="id" />
        <UrlField source="domain" />
        <TextField source="pageType" />
        <TextField source="funnelStep" />
        <BooleanField source="isDownsell" />
        <DateField source="createAt" />
        <DateField source="updateAt" />
      </SimpleShowLayout>
    </Show>
  );
};

export default DomainShow;

import { useEffect, useState } from "react";
import { EditButton, linkToRecord, useUpdate, useNotify } from "react-admin";
import { Link } from "react-router-dom";
import { Props } from "./ClientCard.interface";
import useStyles from "./ClientCard.styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import copy from "clipboard-copy";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
import FileCopyIcon from "@material-ui/icons/FileCopy";

export default function ClientCard({ client }: Props) {
  const { name, isActive, key: clientKey, createAt } = client;
  const linkToClient = linkToRecord("/clients", client.id, "edit");

  const classes = useStyles();
  const notify = useNotify();
  const [switchChecked, setSwitchChecked] = useState(isActive);
  const [updateStatus, { error }] = useUpdate();
  const [copyTooltipText, setCopyTooltipText] = useState("Copy to clipboard");
  const [formattedDate, setFormattedDate] = useState("--/--/--");

  useEffect(() => {
    const createDate = new Date(createAt);
    const createDateFormatted = `${createDate.getDate()}/${createDate.getMonth()}/${createDate.getFullYear()}`;
    setFormattedDate(createDateFormatted);
  }, [createAt]);

  const onCopy = (textToCopy: string) => {
    copy(textToCopy);
    setCopyTooltipText("Copied!");
    setTimeout(() => setCopyTooltipText("Copy to clipboard"), 1000);
  };

  const handleActiveSwitch = () => {
    setSwitchChecked(!switchChecked);
    updateStatus("clients", client.id, { isActive: !switchChecked }, client);
    if (error) {
      notify(error, "error");
      return;
    }
    notify("Client updated", "success");
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <div className={classes.cardHeader}>
          <Link to={linkToClient} className={classes.titleLink}>
            <Typography
              className={classes.title}
              color="textPrimary"
              gutterBottom
            >
              {name}
            </Typography>
          </Link>
          <Switch
            checked={switchChecked}
            onChange={handleActiveSwitch}
            color="primary"
            name="checkedB"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </div>
        <div className={classes.clientKeyInput}>
          <TextField
            type="text"
            variant="standard"
            value={clientKey}
            inputProps={{ readOnly: true }}
          />
          <Tooltip title={copyTooltipText}>
            <Button onClick={() => onCopy(clientKey)}>
              <FileCopyIcon style={{ fontSize: 14 }} />
            </Button>
          </Tooltip>
        </div>
      </CardContent>
      <CardActions className={classes.cardFooter}>
        <Typography className={classes.cardDate}>
          Created on: <span>{formattedDate}</span>
        </Typography>
        <div className={classes.cardActions}>
          <EditButton
            basePath="/clients"
            label=" "
            record={{ id: client.id }}
          ></EditButton>
        </div>
      </CardActions>
    </Card>
  );
}

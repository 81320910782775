import {
  required,
  TextInput,
  Create,
  SimpleForm,
  CreateProps,
  TopToolbar,
  ListButton,
  minLength,
  useNotify,
  useRedirect,
  HttpError,
  number,
  minValue,
  NumberInput,
  BooleanInput,
} from "react-admin";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Typography from "@material-ui/core/Typography";
import useQuery from "../../../hooks/useQuery";

const DomainCreateActions = ({ basePath }: any) => {
  return (
    <TopToolbar>
      <ListButton basePath={basePath} icon={<ChevronLeft />} />
    </TopToolbar>
  );
};

export const DomainCreate = (props: CreateProps) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const queryParams = useQuery();
  const hasClientId = queryParams.has("clientId");
  const clientId = queryParams.get("clientId") || "";
  const shouldRedirect = hasClientId && clientId !== "";
  const onFailure = (error: HttpError) => {
    let message: string = `${error.body.error}`;

    if (typeof error.body.message === "string") {
      message = `${message}: ${error.body.message}`;
    }
    if (Array.isArray(error.body.message)) {
      message = `${message}: ${error.body.message.join(" - ")}`;
    }

    notify(message, "error");
    if(!shouldRedirect) {
      redirect("create", props.basePath);
    }
  };

  return (
    <Create
      {...props}
      actions={<DomainCreateActions basePath={props.basePath} />}
      onFailure={onFailure}
    >
      <SimpleForm
        redirect={shouldRedirect ? `/clients/${clientId}/show/domains` : "list"}
      >
        <Typography color="textPrimary" gutterBottom variant="h6">
          Creating New Domain
        </Typography>
        <TextInput
          source="clientId"
          label="Client ID"
          disabled={shouldRedirect}
          defaultValue={clientId}
          validate={[required()]}
        />
        <TextInput
          source="domain"
          label="Domain"
          validate={[required(), minLength(4)]}
        />
        <TextInput source="pageType" label="Pagetype" validate={required()} />
        <NumberInput
          source="funnelStep"
          label="Funnel Step"
          validate={[
            number("Funnel Step must be a number"),
            minValue(0, "Funnel Step must be a positive number"),
          ]}
        />
        <BooleanInput source="isDownsell" defaultValue={false} label="Is Downsell" />
      </SimpleForm>
    </Create>
  );
};

export default DomainCreate;

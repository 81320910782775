import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  BooleanInput,
  useNotify,
  useRedirect,
  HttpError,
  number,
  minValue,
  NumberInput,
} from "react-admin";
import Typography from "@material-ui/core/Typography";

export const ClientEdit = (props: any) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const onFailure = (error: HttpError) => {
    console.log({ error });
    let message: string = `${error.body.error}`;

    if (typeof error.body.message === "string") {
      message = `${message}: ${error.body.message}`;
    }
    if (Array.isArray(error.body.message)) {
      message = `${message}: ${error.body.message.join(" - ")}`;
    }

    notify(message, "error");
    redirect("list", props.basePath);
  };
  return (
    <Edit {...props} onFailure={onFailure}>
      <SimpleForm>
        <Typography color="textPrimary" gutterBottom variant="h6">
          Editing New SDK Client
        </Typography>
        <TextInput source="name" label="Name" validate={required()} />
        <BooleanInput source="isActive" label="Is Active?" />
        <TextInput
          source="basename"
          label="Basename"
          defaultValue="/"
          validate={required()}
          helperText={`Don't forget "/" prefix for the basename (i.e /test) or if your deployment doesn't have a basename leave it as "/"`}
        />
        <TextInput source="key" label="Key" inputProps={{ readOnly: true }} />
        <TextInput
          source="pricingContainerId"
          label="Pricing Container Id"
          validate={required()}
        />
        <TextInput
          source="pricingContainerType"
          label="Pricing Container Type"
          validate={required()}
        />
        <NumberInput
          source="prodProductGroupId"
          label="Prod Product Group Id"
          validate={[
            number("Product Group Id must be a number"),
            minValue(1, "Product Group Id must be a positive number"),
          ]}
        />
        <NumberInput
          source="devProductGroupId"
          label="Dev Product Group Id"
          validate={[
            number("Product Group Id must be a number"),
            minValue(1, "Product Group Id must be a positive number"),
          ]}
        />
        <NumberInput
          source="konnektiveCampaignId"
          label="Konnektive Campaign Id"
          validate={[
            number("Campaign Key must be a number"),
          ]}
        />
        <TextInput
          source="conversionsApiPixelId"
          label="Conversions API Pixel Id"
        />
        <TextInput
          source="conversionsApiAccessToken"
          label="Conversions API Access Token"
        />
        <NumberInput
          source="prodPayPalMID"
          label="Prod PayPal MID"
          validate={[number("Payment MIDs must be a number")]}
        />
        <NumberInput
          source="devPayPalMID"
          label="Dev PayPal MID"
          validate={[number("Payment MIDs must be a number")]}
        />
        <NumberInput
          source="prodSezzleMID"
          label="Prod Sezzle MID"
          validate={[number("Payment MIDs must be a number")]}
        />
        <NumberInput
          source="devSezzleMID"
          label="Dev Sezzle MID"
          validate={[number("Payment MIDs must be a number")]}
        />
        <NumberInput
          source="prodEBANXMID"
          label="Prod EBANX MID"
          validate={[number("Payment MIDs must be a number")]}
        />
        <NumberInput
          source="devEBANXMID"
          label="Dev EBANX MID"
          validate={[number("Payment MIDs must be a number")]}
        />
      </SimpleForm>
    </Edit>
  );
};

export default ClientEdit;

import React, { useEffect } from "react";
import { Admin, Resource } from "react-admin";
import { useLocation } from "react-router-dom";

import dataProvider from "../dataProvider";
import useLocalStorage from "../hooks/useLocalStorage";
import LocalStorageKeys from "../utils/enums/localStorageKeys";
import { getTokenParam } from "../utils/helpers";
import ClientList from "../components/Client/ClientList";
import MyLayout from "../components/MyLayout";
import ClientCreate from "../components/Client/ClientCreate";
import ClientEdit from "../components/Client/ClientEdit";
import ClientShow from "../components/Client/ClientShow";
import DomainShow from "../components/Domain/DomainShow";
import DomainList from "../components/Domain/DomainList";
import DomainEdit from "../components/Domain/DomainEdit";
import DomainCreate from "../components/Domain/DomainCreate";

const HomePage = () => {
  let { search } = useLocation();
  const [, setLocalToken] = useLocalStorage(
    LocalStorageKeys.TOKEN,
    localStorage.getItem(LocalStorageKeys.TOKEN) || ""
  );

  useEffect(() => {
    const token = getTokenParam(search);
    if (token !== "") {
      setLocalToken(token);
    }
  }, [search, setLocalToken]);

  return (
    <Admin dataProvider={dataProvider} layout={MyLayout}>
      <Resource
        name="clients"
        list={ClientList}
        create={ClientCreate}
        edit={ClientEdit}
        show={ClientShow}
      />
      <Resource
        name="domains"
        list={DomainList}
        show={DomainShow}
        edit={DomainEdit}
        create={DomainCreate}
      />
    </Admin>
  );
};

export default HomePage;

import {
  required,
  BooleanInput,
  TextInput,
  Create,
  SimpleForm,
  CreateProps,
  TopToolbar,
  ListButton,
  minLength,
  useNotify,
  useRedirect,
  HttpError,
  number,
  minValue,
  NumberInput,
} from "react-admin";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Typography from "@material-ui/core/Typography";
import useStyles from "./ClientCreate.styles";

const ClientCreateActions = ({ basePath }: any) => {
  const classes = useStyles();
  return (
    <TopToolbar className={classes.toolbar}>
      <ListButton basePath={basePath} icon={<ChevronLeft />} />
    </TopToolbar>
  );
};

export const ClientCreate = (props: CreateProps) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const onFailure = (error: HttpError) => {
    let message: string = `${error.body.error}`;

    if (typeof error.body.message === "string") {
      message = `${message}: ${error.body.message}`;
    }
    if (Array.isArray(error.body.message)) {
      message = `${message}: ${error.body.message.join(" - ")}`;
    }

    notify(message, "error");
    redirect("create", props.basePath);
  };

  return (
    <Create
      {...props}
      actions={<ClientCreateActions basePath={props.basePath} />}
      onFailure={onFailure}
    >
      <SimpleForm redirect="list">
        <Typography color="textPrimary" gutterBottom variant="h6">
          Creating New SDK Client
        </Typography>
        <BooleanInput
          source="isActive"
          label="Is Active?"
          defaultValue={true}
        />
        <TextInput
          source="name"
          label="Name"
          validate={[required(), minLength(4)]}
        />
        <TextInput
          source="basename"
          label="Basename"
          validate={[required()]}
          defaultValue="/"
          helperText={`Don't forget "/" prefix for the basename (i.e /test) or if your deployment doesn't have a basename leave it as "/"`}
        />
        <TextInput
          source="pricingContainerId"
          label="Pricing Container Id"
          validate={required()}
        />
        <TextInput
          source="pricingContainerType"
          label="Pricing Container Type"
          validate={required()}
        />
        <NumberInput
          source="prodProductGroupId"
          label="Prod Product Group Id"
          validate={[
            number("Product Group Id must be a number"),
            minValue(1, "Product Group Id must be a positive number"),
          ]}
        />
        <NumberInput
          source="devProductGroupId"
          label="Dev Product Group Id"
          validate={[
            number("Product Group Id must be a number"),
            minValue(1, "Product Group Id must be a positive number"),
          ]}
        />
        <NumberInput
          source="konnektiveCampaignId"
          label="Konnektive Campaign Id"
          validate={[
            number("Campaign Key must be a number"),
          ]}
        />
        <TextInput
          source="conversionsApiPixelId"
          label="Conversions API Pixel Id"
          validate={[number("Conversions API Pixel ID must be a number")]}
        />
        <TextInput
          source="conversionsApiAccessToken"
          label="Conversions API Access Token"
        />
        <NumberInput
          source="prodPayPalMID"
          label="Prod PayPal MID"
          validate={[number("Payment MIDs must be a number")]}
        />
        <NumberInput
          source="devPayPalMID"
          label="Dev PayPal MID"
          validate={[number("Payment MIDs must be a number")]}
        />
        <NumberInput
          source="prodSezzleMID"
          label="Prod Sezzle MID"
          validate={[number("Payment MIDs must be a number")]}
        />
        <NumberInput
          source="devSezzleMID"
          label="Dev Sezzle MID"
          validate={[number("Payment MIDs must be a number")]}
        />
        <NumberInput
          source="prodEBANXMID"
          label="Prod EBANX MID"
          validate={[number("Payment MIDs must be a number")]}
        />
        <NumberInput
          source="devEBANXMID"
          label="Dev EBANX MID"
          validate={[number("Payment MIDs must be a number")]}
        />
      </SimpleForm>
    </Create>
  );
};

export default ClientCreate;

import {
  List,
  ListProps,
  Datagrid,
  TextField,
  DateField,
  TopToolbar,
  SortButton,
  CreateButton,
  ExportButton,
  TextInput,
  NumberField,
  ReferenceField,
  BooleanField,
  DeleteButton,
} from "react-admin";
import LinkedResourcesEditButton from "../../LinkedResourcesEditButton";

const ListActions = () => (
  <TopToolbar>
    <SortButton fields={["domain", "SDKVersion", "pageType", "createAt"]} />,
    <CreateButton basePath="/domains" />
    <ExportButton />
  </TopToolbar>
);

const DomainFilters = [
  <TextInput label="Domain" source="domain" alwaysOn />,
  <TextInput label="Sdk Version" source="SDKVersion" alwaysOn />,
  <TextInput label="Page Type" source="pageType" alwaysOn />,
];

const PostList = (props: ListProps) => (
  <List
    {...props}
    filters={DomainFilters}
    actions={<ListActions />}
    bulkActionButtons={false}
  >
    <Datagrid rowClick="show" hasBulkActions={false}>
      <ReferenceField
        source="SDKClient"
        reference="clients"
        sortable={false}
        label="SDK Client"
      >
        <TextField source="name" sortable={false} />
      </ReferenceField>
      <TextField source="domain" />
      <TextField source="SDKVersion" label="SDK Version" />
      <TextField source="pageType" />
      <NumberField source="funnelStep" sortable={false} />
      <BooleanField source="isDownsell" sortable={false} />
      <DateField source="createAt" />
      <DateField source="updateAt" />
      <LinkedResourcesEditButton mainResourceIdKey="id" />
      <DeleteButton label="" />
    </Datagrid>
  </List>
);

export default PostList;

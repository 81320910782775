export const getTokenParam = (search: string): string => {
  const params = new URLSearchParams(search);
  const token = params.get("token");
  return token ? token : "";
};

export const includeKeys = (
  params: any,
  keys: string[]
): any => {
  let paramsData: any = {};
  const editableProperties = [...keys];
  editableProperties.forEach((el) => {
    paramsData[el] = params.data[el];
  });
  return paramsData;
};

import { includeKeys } from "./utils/helpers";
import { fetchUtils } from "react-admin";
import simpleRestProvider from "ra-data-simple-rest";

const { REACT_APP_API_URL: apiEndpoint } = process.env;

const httpClient = (url: string, options: fetchUtils.Options = {}) => {
  if (options?.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = `${localStorage.getItem("token")}`.replace(/"/g, "");
  options.user = {
    authenticated: true,
    token,
  };
  return fetchUtils.fetchJson(url, options);
};

const myDataProvider = simpleRestProvider(apiEndpoint || "", httpClient);

const dataProvider = {
  ...myDataProvider,
  create: (resource: string, params: any) => {
    let paramsData = params.data;

    if (resource === "domains") {
      const { clientId } = params.data;
      paramsData = includeKeys(params, [
        "domain",
        "funnelStep",
        "pageType",
        "isDownsell",
      ]);
      return httpClient(`${apiEndpoint}/clients/${clientId}/${resource}`, {
        method: "POST",
        body: JSON.stringify(paramsData),
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }));
    }
    return httpClient(`${apiEndpoint}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    }));
  },
  update: (resource: string, params: any) => {
    let paramsData = params.data;

    if (resource === "clients") {
      paramsData = includeKeys(params, [
        "name",
        "basename",
        "isActive",
        "prodProductGroupId",
        "devProductGroupId",
        "pricingContainerId",
        "pricingContainerType",
        "konnektiveCampaignId",
        "conversionsApiPixelId",
        "conversionsApiAccessToken",
        "devPayPalMID",
        "prodPayPalMID",
        "devSezzleMID",
        "prodSezzleMID",
        "prodEBANXMID",
        "devEBANXMID",
      ]);
    } else if (resource === "domains") {
      paramsData = includeKeys(params, [
        "domain",
        "funnelStep",
        "pageType",
        "isDownsell",
      ]);
      const clientId = params.data["SDKClient"];
      return httpClient(
        `${apiEndpoint}/clients/${clientId}/${resource}/${params.id}`,
        {
          method: "PATCH",
          body: JSON.stringify(paramsData),
        }
      ).then(({ json }) => ({ data: json }));
    }

    return httpClient(`${apiEndpoint}/${resource}/${params.id}`, {
      method: "PATCH",
      body: JSON.stringify(paramsData),
    }).then(({ json }) => ({ data: json }));
  },
  delete: (resource: string, params: any) => {
    if (resource === "domains") {
      const { id: domainId } = params;
      const { SDKClient: clientId } = params.previousData;

      return httpClient(
        `${apiEndpoint}/clients/${clientId}/${resource}/${domainId}`,
        {
          method: "DELETE",
        }
      ).then(({ json }) => ({ data: json }));
    }

    return httpClient(`${apiEndpoint}/${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ json }) => ({ data: json }));
  },
};

export default dataProvider;

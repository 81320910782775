import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  appbar: {
    background: "#100932",
    color: "#fff",
    "& [aria-label = Profile]": {
      display: "none",
    },
  },
  logoTitle: {
    fontFamily: "sans-serif",
  },
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
});

export default useStyles;

import React from "react";
import { AppBar } from "react-admin";
import Typography from "@material-ui/core/Typography";
import useStyles from "./MyAppBar.styles";

const MyAppBar = (props: any) => {
  const classes = useStyles();
  return (
    <AppBar {...props} color="primary" className={classes.appbar}>
      <Typography
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <h3 className={classes.logoTitle}>Software Development Kit</h3>
      <span className={classes.spacer} />
    </AppBar>
  );
};

export default MyAppBar;

import {
  Show,
  ShowProps,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
} from "react-admin";
import ClientDomainsList from "../ClientDomainsList";

const ClientShow = (props: ShowProps) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TabbedShowLayout>
          <Tab label="summary" path="summary">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="basename" />
            <TextField source="key" />
          </Tab>
          <Tab label="Pricing Containers" path="pricing-containers">
            <TextField source="pricingContainerId" />
            <TextField source="pricingContainerType" />
            <TextField source="devProductGroupId" />
            <TextField source="prodProductGroupId" />
            <TextField label="Konnektive Campaign Id" source="konnektiveCampaignId" />
          </Tab>
          <Tab label="Conversions API" path="conversions-api">
            <TextField source="conversionsApiPixelId" />
            <TextField source="conversionsApiAccessToken" />
          </Tab>
          <Tab label="Payments MIDs" path="payments-mids">
            <TextField source="prodPayPalMID" label="Prod PayPal MID" />
            <TextField source="devPayPalMID" label="Dev PayPal MID" />
            <TextField source="prodSezzleMID" label="Prod Sezzle MID" />
            <TextField source="devSezzleMID" label="Dev Sezzle MID" />
            <TextField source="prodEBANXMID" label="Prod EBANX MID" />
            <TextField source="devEBANXMID" label="Dev EBANX MID" />
          </Tab>
          <Tab label="Domains" path="domains">
            <ClientDomainsList showCreate />
          </Tab>
        </TabbedShowLayout>
      </SimpleShowLayout>
    </Show>
  );
};

export default ClientShow;

import React from "react";
import Typography from "@material-ui/core/Typography";
import useStyles from "./Documentation.styles";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";

const { REACT_APP_DOCUMENTATION_LINK } = process.env;

const DocumentationLink = () => {
  const link = REACT_APP_DOCUMENTATION_LINK || "";
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.textWrapper}>
        <Typography>How do I use my SDK?</Typography>
        &nbsp;
        <InfoRoundedIcon style={{ color: "#100932" }}></InfoRoundedIcon>
      </div>
      <a href={link} target="__blank" className={classes.button}>
        Click to View Step-by-Step Instructions
      </a>
    </div>
  );
};

export default DocumentationLink;

import * as React from "react";

import ContentCreate from "@material-ui/icons/Create";
import { useHistory } from "react-router-dom";
import { ReactNode } from "react";
import { ButtonProps, CloneButton, Record } from "react-admin";
import { Button } from "@material-ui/core";

type LinkedResourcesEditButtonProps = ButtonProps & {
  children?: JSX.Element;
  icon?: ReactNode;
  label: string;
  linkedResourceIdKey?: string;
  linkedResourceName?: string;
  mainResourceIdKey: string;
  record?: Record;
  redirect?: boolean;
  resource?: string;
} & typeof defaultProps;

const defaultProps = Object.freeze({
  icon: <ContentCreate />,
  label: "",
  linkedResourceIdKey: "",
  linkedResourceName: "",
  record: {},
  resource: "",
});

const LinkedResourcesEditButton = ({
  children,
  icon,
  label,
  linkedResourceIdKey,
  linkedResourceName,
  mainResourceIdKey,
  resource,
  record,
  ...rest
}: LinkedResourcesEditButtonProps): JSX.Element => {
  const history = useHistory();
  const linkedResourceOn: boolean =
    linkedResourceIdKey !== "" && linkedResourceName !== "" ? true : false;

  const linkedResourceQuery = linkedResourceOn
    ? `${linkedResourceName}Id=${record[`${linkedResourceIdKey}`]}`
    : "";

  return (
    <CloneButton
      icon={icon}
      component={Button}
      label={label}
      onClick={(event) => {
        event.stopPropagation();
        history.push({
          pathname: `/${resource}/${record[`${mainResourceIdKey}`]}`,
          search: `?${linkedResourceQuery}`,
        });
      }}
      {...rest}
    >
      {children}
    </CloneButton>
  );
};

LinkedResourcesEditButton.defaultProps = defaultProps;
export default LinkedResourcesEditButton;
